/*!
* MDB5
* Version: PRO 8.0.0
*
*
* Copyright: Material Design for Bootstrap
* https://mdbootstrap.com/
*
* Read the license: https://mdbootstrap.com/general/license/
*
*
* Documentation: https://mdbootstrap.com/docs/standard/
*
* Support: https://mdbootstrap.com/support/
*
* Contact: contact@mdbootstrap.com
*
*/

@import './src/scss/mdb.pro.scss'
